<template>
  <div>
    <Navbar :isManager="true"></Navbar>
    <div class="about">
      <img src="@/img/Img1_ComingSoonSearchTool.jpg" alt="Coming Soon Search Tool" />
      <img src="@/img/Img2_howItWorksForParents.jpg" alt="Coming Soon Search Tool" />
      <img src="@/img/Img3_AboutUs.jpg" alt="Coming Soon Search Tool" />
      <img src="@/img/Img4_campsHowItWorks.jpg" alt="Coming Soon Search Tool" />
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
  import Footer from '@/components/Footer.vue'
  import Navbar from '@/components/Navbar.vue'

  export default {
    name: 'About',
    components: {
      'Footer': Footer,
      'Navbar': Navbar
    }
  }
</script>
<style scoped>

img {
  width: 100%;
  height: auto;
}


</style>
